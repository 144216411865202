import { faStar, faUser } from "@fortawesome/free-regular-svg-icons"
import { faHome, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Link from "next/link"
import { useRouter } from "next/router"

export const MobileNavBar = () => {
  const handleLogin = () => {}
  const router = useRouter()
  const menu = [
    { label: "Dom", icon: faHome, link: "/" },
    { label: "Oferta", icon: faMapMarkerAlt, link: "/miejsca" },
    { label: "Inspiracje", icon: faStar, link: "/inspiracje" },
    { label: "Zaloguj się", icon: faUser, onClick: handleLogin },
  ]
  var selected = "Dom"
  if (router.asPath.indexOf("/miejsca") >= 0) {
    selected = "Oferta"
  }
  if (router.asPath.indexOf("/inspiracje") >= 0) {
    selected = "Inspiracje"
  }
  return (
    <div
      id="MobileNavBar"
      className="bg-white fixed left-0 bottom-0 right-0 h-[var(--moje-mobile-header-height)] 
      sm:hidden
      z-10
      "
    >
      <div className="w-full h-full flex flex-row">
        {menu.map((item) => {
          const res = (
            <div
              className={`flex-1 flex flex-col p-1 items-center 
            `}
              style={{
                color:
                  selected === item.label
                    ? "var(--moje-color-orange)"
                    : "var(--moje-color-text-light-gray)",
              }}
            >
              <div className="mt-1">
                <FontAwesomeIcon icon={item.icon} />
              </div>
              <div
                className={`
            text-center text-xs font-medium mb-1
          `}
              >
                {item.label}
              </div>
            </div>
          )
          return item.link ? <Link href={item.link}>{res}</Link> : res
        })}
      </div>
    </div>
  )
}
