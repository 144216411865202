import * as React from "react"
import NextLink from "next/link"
import styled from "styled-components"
// allow this component to accept all properties of "a" tag
interface IProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string
  // we can add more properties we need from next/link in the future
}

// Forward Refs, is useful

const StyledLink = styled.a`
  textdecoration: none !important;
`
export const Link = React.forwardRef(({ href, ...props }: IProps, ref: any) => {
  return (
    <NextLink href={href}>
      <StyledLink
        {...props}
        href={href}
        ref={ref}
        style={{
          textDecoration: "none !important",
        }}
      />
    </NextLink>
  )
})
