import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const IconButtonWrapperStyled = styled.div`
  cursor: pointer;

  :hover {
    background: rgb(247, 247, 247) !important;
    border-radius: 20px;
    ::before {
      background: rgb(247, 247, 247) !important;
    }
  }
  box-sizing: border-box;
  padding: 6px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 8px;

  ::before {
    // content: "" !important;
    // display: block !important;
    // position: absolute !important;
    // top: 50% !important;
    // left: 50% !important;
    // transform: translate(-50%, -50%) !important;
    // width: 32px !important;
    // height: 32px !important;
    // border-radius: 50% !important;
  }
  > .inner {
    width: 10px;
  }
`;

export const IconButton = ({ icon, onClick, size }) => {
  return (
    <IconButtonWrapperStyled onClick={onClick}>
      <div className="inner">
        <FontAwesomeIcon
          fixedWidth
          icon={icon}
          style={{ fontSize: 6 }}
          size="sm"
        />
      </div>
    </IconButtonWrapperStyled>
  );
};
