import { useState } from "react"
import { useRouter } from "next/router"

import { DateSelectorPopup } from "../../../components/widgets/DateSelector"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHome,
  faMapMarkerAlt,
  faSearch,
} from "@fortawesome/free-solid-svg-icons"
import { useQuickSearch } from "../../search/useQuickSearch"
import Link from "next/link"
import { SelectFiltersPopupWrapper } from "./DesktopSearchPopup"

const _SearchLabel = ({ children, small, ...props }) => (
  <label
    className={`cursor-pointer 
text-normal
font-poppins 
font-light 
${small ? " text-xs " : ""}
text-[#7e7e7e]
name`}
    {...props}
  >
    {children}
  </label>
)

const _ValueLabel = ({ children, small, ...props }) => (
  <div
    className={`
  ${small ? " text-xs " : " text-sm "}
  h-5 font-semibold `}
  >
    {children}
  </div>
)

const LocationSelect = ({
  setCurrentStep,
  currentStep,
  small,
  setPlaceSearchString,
  placeSearchString,
  location,
}) => {
  return (
    <div
      className="w-full 
  cursor-pointer p-2 sm:p-1 sm:pl-5 
  rounded-full 
  border-gray-800
  "
      onClick={(e) => {
        setCurrentStep(currentStep ? undefined : "place")
        e.preventDefault()
      }}
    >
      <_SearchLabel htmlFor="place" small={small}>
        Gdzie?
      </_SearchLabel>
      <_ValueLabel small={small}>
        {currentStep == "place" && (
          <input
            id="place"
            className="h-5 
          bg-transparent py-1 border-none
           focus:outline-none focus:border-none focus:shadow-none"
            autoFocus
            onChange={(event) => {
              setPlaceSearchString(event.currentTarget.value)
            }}
            value={placeSearchString}
          />
        )}
        {currentStep != "place" && (
          <>{location?.name ?? "Wybierz lokalizację"}</>
        )}

        {/* {currentStep != "place" && location && (
      <span>{location.name}</span>
    )} */}
      </_ValueLabel>
    </div>
  )
}
export const HomeSearchBar = (props) => {
  const router = useRouter()
  const [location, setLocation] = useState(
    router && router.query && router.query.near_name
      ? {
          name: router.query.near_name,
          lat: Number(router.query.near_lat),
          lng: Number(router.query.near_lng),
        }
      : undefined
  )

  const [placeSearchString, setPlaceSearchString] = useState(undefined)
  const { result: quickSearchResult } = useQuickSearch(placeSearchString)
  const [maxDistance, setMaxDistance] = useState(undefined)
  const [date, setDate] = useState(
    router && router.query && router.query.date !== "undefined"
      ? router.query.date
      : undefined
  )
  const [currentStep, setCurrentStep] = useState(undefined)

  const onStartSearch = () => {
    console.log("onStartSearch", location, maxDistance)

    var url = `/search?near_name=${location?.name}`
    if (location) {
      url += `&near_lat=${location.lat}&near_lng=${location.lng}`
    }
    if (date) {
      url += `&date=${date}`
    }
    console.log("push", url)
    router.push(url)
  }

  const handlePlaceSelected = (place) => {
    console.log("handlePlaceSelected")
    setLocation(place)
    setCurrentStep("date")
  }
  const renderSearchPopupWrapper = () => {
    const afterLocationSelected = () => {
      setCurrentStep(undefined)
      console.log("afterLocationSelected")
      onStartSearch()
    }

    return (
      <SelectFiltersPopupWrapper
        currentStep={currentStep}
        isOpen={!!currentStep}
        onClose={() => setCurrentStep(undefined)}
        onPlaceSelected={handlePlaceSelected}
        onDateSelected={(newDate) => {
          setDate(newDate)
          setCurrentStep(undefined)
          onStartSearch()
        }}
      >
        <div className="optionsWrapper">
          <div style={{ flex: 1 }}>
            <div
              onClick={() => {
                setCurrentStep(undefined)
              }}
            >
              <div>Pomin</div>
            </div>
          </div>
          <div className="searchButton" onClick={onStartSearch}></div>
        </div>
      </SelectFiltersPopupWrapper>
    )
  }

  return (
    <div
      className={`mx-auto bg-white 
      relative rounded-full 
      ${
        props.small
          ? " w-[345px] "
          : " w-[90%] sm:w-[500px] xl:w-[746px]  xl:pl-8 "
      }
     
      ${props.bordered ? "border-gray-100 border" : ""}
      `}
    >
      <div
        className={`flex w-full 
        ${props.small ? " sm:h-12 " : " sm:h-16 "}
        p-2 items-center     
        ${props.headerMode ? " border border-gray-100 rounded-full " : ""} `}
      >
        {/* Selectors for web */}
        <div
          className="flex flex-col sm:flex-row flex-auto
          hidden sm:flex
        "
        >
          {/* Gdzie */}
          <LocationSelect
            setCurrentStep={setCurrentStep}
            setPlaceSearchString={setPlaceSearchString}
            placeSearchString={placeSearchString}
            location={location}
            currentStep={currentStep}
            small={props.small}
          />

          <div className="bg-[#E8E8E8] w-[1px] h-[80%] m-auto">&nbsp;</div>
          {/* Kiedy  */}
          <div
            className="w-full cursor-pointer 
              p-2 sm:p-1 sm:pl-5 rounded-full sm:rounded-tl-none sm:rounded-bl-none hover:bg-gray-100"
            onClick={() => {
              setCurrentStep(currentStep ? undefined : "date")
            }}
          >
            <_SearchLabel small={props.small}>Kiedy?</_SearchLabel>
            <_ValueLabel small={props.small}>
              {date ?? "Wybierz datę"}
            </_ValueLabel>
          </div>
          <div
            onClick={onStartSearch}
            className={`}
            mr-1
            cursor-pointer
            opacity-95 hover:opacity-100
            text-white bg-[#E30074] icon rounded-full 
            ${
              props.small
                ? " w-[28px] h-[28px] min-w-[28px] "
                : " w-[50px] h-[50px] min-w-[50px]"
            }
            
            flex flex-col items-center justify-center"
            `}
          >
            <FontAwesomeIcon
              icon={faSearch}
              size={props.small ? "sm" : "2x"}
              className="m-auto"
            />
          </div>
        </div>
        {/* Selectors for mobile only  */}
        <div
          className={`flex sm:hidden flex-row flex-1
          items-center
          `}
          onClick={() => setCurrentStep("place")}
        >
          <FontAwesomeIcon
            icon={faSearch}
            className="mr-2 pl-2 text-2xl"
            style={{
              color: "var(--moje-color-magenta) ",
            }}
          />
          <div
            className="text-sm 
              lg:p-2 
              text-center flex-1"
            style={{
              color: "var(--moje-color-text-light-gray)",
            }}
          >
            Gdzie organizujesz przyjęcie
          </div>
        </div>

        {currentStep && renderSearchPopupWrapper()}
      </div>
    </div>
  )
}
