import React, { useEffect, useState, useRef } from "react"
import axios from "axios"

const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useQuickSearch = (searchQuery) => {
  const [loading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>(undefined)
  const previousSearchQuery = usePrevious(searchQuery)
  const [result, setResult] = useState(undefined)

  const doQuickSearch = async () => {
    console.log("doQuickSearch", searchQuery)
    // if (!searchQuery || !searchQuery.query) {
    //   console.log("Missing params - skip search")
    //   return
    // }
    setIsLoading(true)
    // do call
    const url = process.env.NEXT_PUBLIC_API_URL + `/public/quickSearch`
    try {
      const result = await axios.post(url, { query: searchQuery })

      console.log("Result", result)
      if (result && result.status == 200) {
        setIsLoading(false)
        setResult(result.data)
      }
    } catch (err) {
      console.error("Error doing search", err)
      setIsLoading(false)
      setResult(undefined)
      setError("Problem z wyszkukiwaniem...")
    }
  }

  useEffect(() => {
    if (JSON.stringify(previousSearchQuery) != JSON.stringify(searchQuery)) {
      doQuickSearch()
    }
  }, [searchQuery])

  return {
    loading,
    doQuickSearch,
    result,
    error,
  }
}
