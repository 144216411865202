import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import {
  addDays,
  startOfWeek,
  addWeeks,
  format,
  startOfMonth,
  addMonths,
  parseISO,
} from "date-fns"
import {
  faCalendar,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { IconButton } from "../ui/IconButton"

const DateSelectorWrapperStyled = styled.div`
  position: relative;
`

const DateSelectorPopupStyled = styled.div`
  // position: absolute;
  left: 0px;
  background-color: white;
  // padding: 16px 32px;
  border-radius: 30px;
  // box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px !important;
  // margin-top: 12px !important;
  // height: 300px;
  // width: 300px;
  z-index: 1;

  // :hover {
  //   background-color: #f0f0f0;
  // }
`

const DaysTableStyled = styled.table`
  width: 100%;
  table-layout: fixed;

  tr,
  td,
  th {
    width: 100%;
  }
  td {
    text-align: center;
  }
  .dayWrapper {
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 40px;
    border: 1px solid transparent;
    padding: 8px;
    width: 36px;
    height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    :hover {
      border: 1px solid #eee;
    }
  }
  .dayWrapper.available {
    background-color: #cfead3;
    // border: 1px solid green;
  }
  .dayWrapper.unavailable {
    // background-color: green;
    border: 1px solid red;
  }
`

const dowNames = ["Pon", "Wt", "Sr", "Czw", "Pt", "Sob", "Ndz"]

export const DateSelectorPopup = (props) => {
  const [weeksData, setWeeksData] = useState([])
  const [startDate, setStartDate] = useState(startOfMonth(new Date()))

  const handleDateSelected = (date) => {
    props.onDateSelected(format(date, "dd-MM-yyyy"))
  }

  console.log("DateSelectorPopup", props)

  const recalculateWeeks = () => {
    var weeks = []
    var weekStart = startOfWeek(startDate, { weekStartsOn: 1 })
    for (var i = 0; i < 5; i++) {
      weeks.push({
        startDate: weekStart,
      })
      weekStart = addWeeks(weekStart, 1)
    }
    setWeeksData(weeks)
  }

  useEffect(() => {
    recalculateWeeks()
  }, [startDate])

  const renderWeekDays = (week) => {
    var widgets = []
    for (var i = 0; i < 7; i++) {
      var day = addDays(week.startDate, i)
      if (day.getMonth() !== startDate.getMonth()) {
        widgets.push(<td></td>)
      } else {
        widgets.push(
          <td
            onClick={(event) => {
              handleDateSelected(day)
              event.preventDefault()
              event.stopPropagation()
            }}
          >
            <div className={`dayWrapper `}>
              <div style={{ fontWeight: "bold" }}>{day.getDate()}</div>
              {/* {props.showAvailability && (
                <div style={{ fontSize: 8, padding: 2 }}>3 terminy</div>
              )} */}
              {props.showPrices && <div>150zl</div>}
            </div>
          </td>
        )
      }
    }
    return <>{widgets}</>
  }

  const changeMonth = (delta) => {
    setStartDate(addMonths(startDate, delta))
  }
  const renderHeaders = () => {
    var widgets = []
    for (var i = 0; i < 7; i++) {
      widgets.push(<th>{dowNames[i]}</th>)
    }
    return <>{widgets}</>
  }

  return (
    <DateSelectorPopupStyled>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 8,
          marginBottom: 8,
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <IconButton
          onClick={() => changeMonth(-1)}
          icon={faChevronLeft}
          size="xs"
        />
        <div style={{ flex: 1, textAlign: "center" }}>
          {format(startDate, "LLLL")}
        </div>
        <IconButton
          onClick={() => changeMonth(1)}
          icon={faChevronRight}
          size="xs"
        />
      </div>
      <DaysTableStyled>
        <tbody>
          <tr>{renderHeaders()}</tr>
          {weeksData.map((week, index) => {
            return <tr key={index}>{renderWeekDays(week)}</tr>
          })}
        </tbody>
      </DaysTableStyled>
    </DateSelectorPopupStyled>
  )
}

export const DateSelector = (props) => {
  const [isLocating, setIsLocating] = useState(false)
  const [position, setPosition] = useState(undefined)
  const [isExpanded, setIsExpanded] = useState(props.expanded)

  const [selectedDate, setSelectedDate] = useState(props.value)

  const renderInner = () => {
    return (
      <DateSelectorWrapperStyled
        style={{ display: "flex", flexDirection: "row" }}
      >
        {/* <div style={{ display: "flex" }}>
          {selectedDate && (
            <div style={{ fontWeight: "bold" }}>{selectedDate}</div>
          )}
          <div style={{ width: 20, height: 20, display: "flex" }}>
            <FontAwesomeIcon icon={faCalendar} size="sm" />
          </div>
        </div> */}
        {isExpanded && (
          <DateSelectorPopup
            onClose={() => {
              setIsExpanded(false)
            }}
            onDateSelected={(date) => {
              setSelectedDate(format(date, "yyyy-MM-dd"))
              setIsExpanded(false)
              props.onChange(format(date, "yyyy-MM-dd"))
            }}
          />
        )}
      </DateSelectorWrapperStyled>
    )
  }

  if (props.renderAsFilter) {
    return (
      <div
        className="filter"
        style={{ minWidth: 160, borderRadius: 50 }}
        onClick={() => {
          setIsExpanded(true)
        }}
      >
        <div className="name">Kiedy?</div>
        <div>{renderInner()}</div>
      </div>
    )
  } else {
    return renderInner()
  }
}
