import styled from "styled-components"

import useOnclickOutside from "react-cool-onclickoutside"
import { LocationSelector } from "./LocationSelector"
import { DateSelector } from "./DateSelector"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

export const HomeSearchFormWrapperStyled = styled.div<any>`
  position: absolute;
  height: 250px;
  left: 0;
  right: 0;
  top: 80px;
  display: none;
  z-index: 20;

  .label {
    font-weight: 800;
    font-size: 10px;
    line-height: 22px;
    letter-spacing: 0.2px;
  }
  .popularItem {
    padding: 8px 4px;
    color: #999;
    font-size: 14px;
    line-height: 18px;
    border-radius: 4px;

    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
  }

  ${(props) =>
    props.step &&
    `
  display:initial;
`}
  ${(props) =>
    props.step === "place" &&
    `
    .placeWrapper {
      display:block !important;
    }
`}

${(props) =>
    props.step === "date" &&
    `
.dateWrapper {
  display:block !important;
}
`}
.placeWrapper {
    display: none;
    background-color: #efefef;
    width: 500px;
    min-height: 150px;
    max-height: 400px;
  }

  .dateWrapper {
    display: none;
    background-color: orange;
    width: 300px;
    height: 300px;
    margin-left: 150px;
  }

  .placeWrapper,
  .dateWrapper {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 16px 32px,
      rgba(0, 0, 0, 0.1) 0px 3px 8px !important;
    background-color: white;
    border-radius: 25px;
    padding: 12px;
  }

  .searchButton {
    cursor: pointer;
    width: 44px;
    background-color: yellow;
  }
  .optionsWrapper {
    display: none;
  }

  @media (max-width: 600px) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 100%;
    z-index: 100;

    .placeWrapper,
    .dateWrapper {
      width: 100%;
      box-sizing: border-box;
      border-radius: 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      margin-left: 0 !important;
      min-height: 100%;
    }

    .dateWrapper {
    }

    .optionsWrapper {
      display: block;
      background-color: yellow;
      border-top: 1px solid #dedede;
      padding: 16px;
      height: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      bottom: 0;
      position: fixed;
      left: 0;
      right: 0;
      z-index: 101;
    }
  }
`

const MobileTitle = ({ children }) => {
  return (
    <div
      className="sm:hide rounded-full border border-gray-200 
        px-4 py-3
        text-center mb-4
        flex flex-row
        justify-center
        items-center
        "
      style={{
        color: "var(--moje-color-text-light-gray)",
      }}
    >
      <div className="mr-2 text-xl">
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
      <div className="text-light flex-1  text-center">{children}</div>
    </div>
  )
}

export const SelectFiltersPopupWrapper = ({
  isOpen,
  onClose,
  currentStep,
  children,
  onPlaceSelected,
  onDateSelected,
}) => {
  const ref = useOnclickOutside(() => {
    if (isOpen) {
      onClose()
    }
  })
  return (
    <div
      id="searchPopupWrapper"
      className={`
        fixed sm:absolute
        h-[90%] sm:h-[250px]
        left-0 right-0
        sm:top-[80px]
        bottom-0
        rounded-t-xl sm:rounded-xl
        ${currentStep ? " block " : " hidden "}
        p-4
        z-20
        bg-white
        animate__slideInUp 
        animate__animated
        `}
      //
      style={{
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
      }}
      ref={ref}
    >
      {currentStep == "place" && (
        <>
          <MobileTitle>Gdzie organizujesz przyjęcie?</MobileTitle>
          <LocationSelector onPlaceSelected={onPlaceSelected} />
        </>
      )}
      {currentStep == "date" && (
        <>
          <MobileTitle>Podaj datę przyjęcia?</MobileTitle>
          <DateSelector onClose={onClose} onDateSelected={onDateSelected} />
        </>
      )}
    </div>
  )
}
