import styled from "styled-components"
import useOnclickOutside from "react-cool-onclickoutside"
import Link from "next/link"
import { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

export const MainNavStyled = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;

  transform: scale(0);
  transition: transform 0s 0.2s, -webkit-transform 0s 0.2s;

  .DropDownNav {
    background-color: #fff;
    border-radius: 4px;
    transform: translateY(-20px);
    padding: 16px;
    transition: opacity 0.2s, transform 0.2s, -webkit-transform 0.2s;
    opacity: 0;
    box-shadow: 0 10px 15px rgb(0 0 0 / 10%);
    margin: auto;
  }

  &.open {
    transform: scale(1);
    transition: transform 0s 0s, -webkit-transform 0s 0s;

    .DropDownNav {
      transform: translateY(0px);
      opacity: 1;
      //   transition: transform 0s 0s, -webkit-transform 0s 0s;
      //   display: initial;
    }
  }
`

const NavItemTitle = ({ children }) => (
  <div className="my-2 text-lg font-bold ">{children}</div>
)

const NavItem = ({ children, link }: { children: any; link?: string }) => {
  const inner = <div className="my-1 font-bold cursor-pointer">{children}</div>
  return link ? <Link href={link}>{inner}</Link> : inner
}

export const MenuItemWithDropdown = ({
  title,
  onClose,
  children,
}: {
  title: string
  onClose: Function
  children?: any
}) => {
  const [isOpened, setIsOpened] = useState(false)

  const ref = useOnclickOutside((ev) => {
    console.log("Outside", ev)
    ev.preventDefault()
    ev.stopImmediatePropagation()
    setIsOpened(false)
  })
  return (
    <div
      className="item noselect"
      //   css={[active && tw`text-themeOrange border-b-2 border-themeOrange`]}
    >
      <div
        className="label flex flex-row"
        onClick={(ev) => {
          console.log("OnClick", isOpened)
          setIsOpened(!isOpened)
          ev.preventDefault()
        }}
      >
        <div className="mr-2">{title}</div>
        <div>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </div>
      <MainNavStyled
        className={`${isOpened ? "open" : ""}`}
        style={{
          width: "100%",
        }}
      >
        <div
          ref={ref}
          className="DropDownNav container relative      p-4  
        "
        >
          {children}
        </div>
      </MainNavStyled>
    </div>
  )
}
