import { DateSelectorPopup } from "../../../components/widgets/DateSelector"

export const DateSelector = ({ onClose, onDateSelected }) => {
  return (
    <div className="dateWrapper">
      <DateSelectorPopup
        onClose={onClose}
        onDateSelected={onDateSelected}
        renderAsFilter={false}
        expanded={true}
      />
    </div>
  )
}
