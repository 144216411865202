import dynamic from "next/dynamic"
import Link from "next/link"
import { useRouter } from "next/router"
import { useState, useEffect } from "react"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import styled from "styled-components"
import { HomeSearchBar } from "../../modules/home/search/HomeSearchBar"
import { TopNavBar } from "./menu/TopNavBar"
import { MobileNavBar } from "./menu/MobileNavBar"
import { MobileTopBar } from "./menu/MobileTopBar"

const HeaderUserSection = dynamic(() => import("./HeaderUserSection"), {
  ssr: false,
})

const ButtonDlaFirm = () => (
  <a href="https://admin.mojeurodziny.pl" target="_blank">
    <div
      className="mx-2 px-4 py-2"
      style={{
        backgroundColor: "#f29100",
        color: "white",
        cursor: "pointer",
        fontWeight: 600,
        borderRadius: 100,
      }}
    >
      Dla firm
    </div>
  </a>
)

// Content of header
const MojeHeaderStyled = styled.div<any>`
  max-width: var(--moje-site-width, 1440px) !important;
  width: 100%;

  ${(props) =>
    props.small
      ? `

      border-radius: 24px;
      box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2) !important;
      padding: 6px;

      @media (max-width: 600px) {
        height: 48px !important;
      }
  `
      : `

  height: 60px !important;
  @media (max-width: 600px) {
        height: 64px !important;
        font-size: 12px;
      }
  `}

  background-color: white;
  transition: height 200ms ease;
  margin-left: auto;
  margin-right: auto;

  .searchWrapper {
    display: none;
    height: 60px;
    transition: translate 200ms ease;
    // transform: translate3d(0, -10px), 0));
    transform: translate(0, -20px) ease;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 600px) {
    // height: 64px !important;

    .searchWrapper {
      display: none !important;
    }
  }
  ${(props) =>
    props.webOnly &&
    `
  @media (max-width: 600px) {
      display:none !important;
  }
  `}

  ${(props) =>
    props.expanded &&
    `
      // height: 160px !important;

      .searchWrapper {
        display: flex;
        transform: translate(0,0);
      }
      
  `}

  .item {
    height: 100%;
    padding: 4px 4px;

    margin: 0 16px;
    display: flex;
    align-items: center;
    font-weight: 600;

    .label {
      cursor: pointer;
      transition: color 150ms ease-in;
    }

    &:hover {
      .label {
        color: var(--moje-color-magenta);
      }
    }
  }

  .selected {
    font-weight: bold;
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
`
const MojeHeaderContentStyled = styled.div`

 >  .title {
      padding: 0 20px;
      font-size: 16px;
      font-weight: bold;
      z-index: 2;
    }
  }
`

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: (props: any) => ({
      color: theme.palette.common.black,
      background: theme.palette.background.paper,
      boxShadow: "0 0 20px rgba(100, 100, 100, 0.1)",
      // ...(props.small ? tw`text-sm md:text-lg` : {}),
    }),
  })
)

function HeaderItem({ href, ...props }) {
  const router = useRouter()
  const active = router.pathname === href
  return (
    <Link href={href} passHref prefetch={false}>
      <a className="hover:bg-[#f0f0f0]">
        <div
          className={`item noselect ${
            active ? "text-themeOrange border-b-2 border-themeOrange" : ""
          }`}
          {...props}
        >
          {props.children}
        </div>
      </a>
    </Link>
  )
}

export const MojeHeader = (props) => {
  const classes = useStyles(props)
  const [expanded, setExpanded] = useState(false)
  const [dropNavBar, setDropNavBar] = useState<any>()
  const router = useRouter()
  const [isScrolled, setIsScrolled] = useState(false)

  const onscroll = (event) => {
    const _target = event.target.documentElement.scrollTop > 70
    if (isScrolled != _target) {
      setIsScrolled(_target)
    }
  }
  useEffect(() => {
    if (window) {
      window.addEventListener("scroll", onscroll)
      return () => {
        console.log("Remove listener")
        window.removeEventListener("scroll", onscroll)
      }
    }
  })

  var currentSearchParmas = undefined
  const handleBack = () => {}
  // extract current search params from roter
  if (router.query && router.query.near_name) {
    currentSearchParmas = { name: router.query.near_name }
    if (router.query.date) {
      currentSearchParmas.date = router.query.date
    }
  }

  return (
    <>
      <div
        className="
      fixed 
      bottom-0 w-full
      left-0 right-0
      md:top-0 
      hidden lg:block
      bg-white
      z-20
      h-[var(--moje-header-height)]
     
    "
        style={{}}
      >
        {/* <AppBar
      position={props.fixed ? "fixed" : "relative"}
      classes={{ root: classes.appBar }}
      className="hidden lg:block"
    > */}
        <div className="md:px-8 h-full">
          {/* <MojeHeaderStyled expanded={expanded} small={props.small} {...props}> */}
          <div
            className="xl:max-w-screen-xl m-auto 
            flex flex-row items-stretch jistify-center w-full h-full"
          >
            {!props.profile && (
              <>
                <div
                  className={`p-2 
                  mr-6
                  cursor-pointer
                  flex items-center justify-center
                  ${props.small ? "hidden" : " hidden md:flex"}`}
                >
                  <div>
                    <Link href="/">
                      <img
                        style={{
                          objectFit: "contain",
                        }}
                        className="sm:h-12"
                        src="/assets/logo_color_xs.png"
                      />
                    </Link>
                  </div>
                </div>
              </>
            )}

            {props.profileMode && <div className="flex-1" />}
            {!props.profileMode && props.title && (
              <div className="title" onClick={handleBack}>
                {"<"} {props.title}
              </div>
            )}
            {!props.profileMode && (
              <div className="flex flex-auto items-stretch h-full ">
                <TopNavBar articles={props.articles} />
                <div className="flex-1 bg-yellow flex flex-row items-center justify-center">
                  <div
                    className={`animate__animated animate__fadeIn ${
                      isScrolled ? "block" : "hidden"
                    } `}
                  >
                    <HomeSearchBar bordered small />
                  </div>
                </div>
                {props.showSearch && (
                  <>
                    <div
                      onClick={() => {
                        setExpanded(!expanded)
                      }}
                      className="hidden md:block m-auto"
                      style={expanded ? { display: "none" } : {}}
                    >
                      {/* <HeaderLetsStartSearchButton>
                      {currentSearchParmas ? (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div style={{ flex: 1 }}>
                            {currentSearchParmas.name}
                          </div>
                        </div>
                      ) : (
                        <span>Znajdz sale zabaw</span>
                      )}
                    </HeaderLetsStartSearchButton> */}
                    </div>
                    {/* This search bar is visible only on desktop */}
                    <div
                      className="hidden h-8 items-center m-auto"
                      style={
                        expanded
                          ? {
                              display: "flex",
                              transform: "translate(0,0)",
                              height: 160,
                            }
                          : {}
                      }
                    >
                      <HomeSearchBar headerMode />
                    </div>
                  </>
                )}
              </div>
            )}
            <div className="flex flex-row items-center">
              <ButtonDlaFirm />

              <HeaderUserSection />
            </div>
          </div>
        </div>
      </div>
      <MobileNavBar />
      {isScrolled && <MobileTopBar />}
    </>
  )
}
