import { HomeSearchBar } from "../../../modules/home/search/HomeSearchBar"

export const MobileTopBar = () => {
  return (
    <div
      className="
        bg-white 
        h-16
        sm:hidden
        fixed
        top-0 left-0 right-0
        z-10
        shadow
        flex flex-col
        px-4 py-2
        items-center
        justify-center
    "
    >
      <HomeSearchBar headerMode />
    </div>
  )
}
