import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"

import {
  HomeSectionCategories,
  NavDropMenuItem,
  Uslugi,
} from "../../../modules/home/HomeSectionCategories"
import { MainNavStyled, MenuItemWithDropdown } from "./MenuItemWithDropdown"
import useOnclickOutside from "react-cool-onclickoutside"
import { Link } from "../../Link"

export const MenuItem = ({
  label,
  onClick,
  dropdown,
  isOpen,
  width,
  onClose,
}) => {
  const ref = useOnclickOutside(() => {
    if (isOpen) {
      onClose()
    }
  })
  return (
    <div className="item h-full p-2 flex items-center noselect relative">
      <div className="label flex flex-row" onClick={onClick}>
        <div
          className="mr-2 
          md:text-medium font-medium"
        >
          {label}
        </div>
        {dropdown && (
          <div>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        )}
      </div>
      <MainNavStyled
        className={`${isOpen ? "open" : ""}`}
        style={{
          width: "100%",
        }}
      >
        {dropdown && (
          <div
            style={{ width: `${width}px` }}
            ref={ref}
            className="DropDownNav container relative p-4"
          >
            {dropdown}
          </div>
        )}
      </MainNavStyled>
    </div>
  )
}

export const DropNavInspiracje = ({ articles }) => {
  const firstArtices =
    articles && Array.isArray(articles)
      ? articles.slice(0, Math.min(articles.length, 6))
      : []
  return (
    <div className="grid grid-cols-12">
      {firstArtices.map((art: any) => (
        <div className="col-span-12 my-2 font-normal">
          <Link href={`/inspiracje/${art.slug}`}>{art.title}</Link>
        </div>
      ))}
    </div>
  )
}

export const DropNavLokalizacje = () => {
  const lokalizacje = [
    { label: "Warszawa", href: "/miejsce/warszawa" },
    { label: "Kraków", href: "/miejsce/krakow" },
    { label: "Łódź", href: "/miejsce/lodz" },
    { label: "Katowice", href: "/miejsce/katowice" },
  ]
  return (
    <div className="grid grid-cols-12">
      {lokalizacje.map((element: any) => (
        <div className="col-span-12 my-2 font-normal">
          <Link href={element.href}>{element.label}</Link>
        </div>
      ))}
    </div>
  )
}
const MenuDropdownPortal = () => {
  const items = [
    {
      label: "Kontakt",
      href: "/kontakt",
    },
    {
      label: "Praca",
      href: "/kariera",
    },
    {
      label: "Polityka Prywatności",
      href: "/polityka-prywatnosci",
    },
    {
      label: "Warunki korzystania",
      href: "/regulamin",
    },
    {
      label: "Projektu UE",
      href: "/projekty",
    },
    {
      label: "FAQ",
      href: "/faq",
    },
  ]
  return (
    <div className="grid grid-cols-12">
      {items.map((item: any) => (
        <div className="col-span-12 my-2 font-normal">
          <NavDropMenuItem label={item.label} href={item.href} />
        </div>
      ))}
    </div>
  )
}
export const TopNavBar = ({ articles }) => {
  const [selectedMenu, setSelectedMenu] = useState<any>()

  // const menuItems = [
  //   { label: "Miejsca", dropdown: <HomeSectionCategories />, width: 600 },
  //   { label: "Usługi", dropdown: <Uslugi />, width: 250 },
  //   {
  //     label: "Inspiracje",
  //     dropdown: <DropNavInspiracje articles={articles} />,
  //     width: 250,
  //   },
  //   { label: "Lokalizacje", dropdown: <DropNavLokalizacje />, width: 250 },
  //   { label: "Portal", dropdown: <MenuDropdownPortal />, width: 250 },
  // ]
  const menuItems: any = [
    {
      label: "Oferta",
    },
    {
      label: "Inspiracje",
    },
  ]

  const handleMenuItemClick = (ev, item) => {
    setSelectedMenu(selectedMenu?.label === item.label ? undefined : item)
  }

  const ref = useOnclickOutside((ev) => {
    console.log("Outside", ev)
    ev.preventDefault()
    ev.stopImmediatePropagation()
    setSelectedMenu(undefined)
  })
  const handleClose = (ev, item) => {
    console.log("handleClose", item)
    // if (selectedMenu === item) {
    setSelectedMenu(undefined)
    // }
  }
  return (
    <div
      className="flex-row h-full hidden sm:flex
     
      "
    >
      {menuItems.map((mi) => (
        <MenuItem
          label={mi.label}
          isOpen={selectedMenu?.label === mi.label}
          onClick={(ev) => handleMenuItemClick(ev, mi)}
          dropdown={mi.dropdown}
          width={mi.width}
          onClose={(ev) => handleClose(ev, mi)}
        />
      ))}
    </div>
  )
}

export const MiejscaDropdown = (props: any) => {
  return (
    <MenuItemWithDropdown title="Miejsca" onClose={props.onClose}>
      <HomeSectionCategories />
    </MenuItemWithDropdown>
  )
}

export const UslugiDropdown = (props: any) => {
  return (
    <MenuItemWithDropdown title="Usługi" onClose={props.onClose}>
      <Uslugi />
    </MenuItemWithDropdown>
  )
}

export const InspiracjeDropdown = (props: any) => {
  return (
    <MenuItemWithDropdown title="Inspiracje" onClose={props.onClose}>
      <Uslugi />
    </MenuItemWithDropdown>
  )
}

export const LokalizacjeDropdown = (props: any) => {
  return (
    <MenuItemWithDropdown title="Lokalizacje" onClose={props.onClose}>
      <Uslugi />
    </MenuItemWithDropdown>
  )
}

export const PortalDropdown = (props: any) => {
  return (
    <MenuItemWithDropdown title="Portal" onClose={props.onClose}>
      <Uslugi />
    </MenuItemWithDropdown>
  )
}
