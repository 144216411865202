import React from "react"
import styled from "styled-components"

const PageLeftSideStyled = styled.div`
  position: relative !important;
  width: 58.3333% !important;
  margin-left: 0% !important;
  margin-right: 0% !important;

  background-color: #fefefe;
`

export const CenteredPageWrapper = ({
  component = "div",
  ...props
}: {
  component?: React.ElementType<any>
} & React.ComponentPropsWithoutRef<"div">) => {
  const RootComponent = component
  return (
    <RootComponent
      {...props}
      className={`container max-w-screen-xl mx-auto px-4 sm:px-2 ${props.className}`}
    />
  )
}

export const CenteredPageContent = styled.div<any>`
  padding-left: 80px;
  padding-right: 80px;

  // For Mobile
  @media (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;

    .content {
      width: 100%;
    }

    .toolbox {
      padding-left: 20px;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100% !important;
      position: fixed;
      height: 80px;
      background-color: white;
      z-index: 1;
    }
    .mobile {
      display: initial;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: white;
    }
  }

  @media (min-width: 600px) {
    > .content {
      width: 60%;
    }

    > .toolbox {
      width: 40%;
    }
    .mobile {
      display: none;
    }
  }

  ${(props) =>
    props.useTwoColumns &&
    `
      display: flex;
      flex-direction: row;
  `}
`
export const PageLeftSideWrapper = (props) => {
  return <PageLeftSideStyled>{props.children}</PageLeftSideStyled>
}
