const PopularPlaceItem = ({ children, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="text-normal 
          my-6 sm:my-2
          cursor-pointer
          p-1
          rounded
          hover:bg-slate-100
          transition-all
    "
    >
      {children}
    </div>
  )
}

export const LocationSelector = ({ onPlaceSelected }) => {
  const popularLocations = [
    { name: "Łódź, łódzkie" },
    { name: "Warszawa, mazowieckie" },
    { name: "Katowice, śląskie" },
    { name: "Kraków, małopolskie" },
  ]

  return (
    <div
      className={` 
          bg-white
          rounded-2xl
          z-20
        `}
      id="placeWrapper w-full "
    >
      {/* {quickSearchResult?.result?.venues &&
            quickSearchResult?.result?.venues.length > 0 && (
              <>
                <div className="label uppercase font-bold text-sm tracking-widest">
                  Sale
                </div>
                {quickSearchResult?.result?.venues.map((v: any) => {
                  return (
                    <Link href={`/miejsce/${v.venue.slug ?? v.venue.id}`}>
                      <a href={`/miejsce/${v.venue.slug ?? v.venue.id}`}>
                        <div className="flex flex-row py-1 popularItem">
                          <div className="mr-1">
                            <FontAwesomeIcon icon={faHome} />
                          </div>
                          <div>{v.venue?.name}</div>
                        </div>
                      </a>
                    </Link>
                  )
                })}
              </>
            )}

          {quickSearchResult?.result?.locations &&
            quickSearchResult?.result?.locations.length > 0 && (
              <>
                <div className="label uppercase font-bold text-sm tracking-widest">
                  Miasta
                </div>
                {quickSearchResult?.result?.locations
                  .filter((v) => v.name && v.code)
                  .map((v: any) => {
                    return (
                      <Link href={`/miasto/${v.code?.toLowerCase()}`}>
                        <div className="flex flex-row py-1 popularItem">
                          <div className="mr-1">
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                          </div>
                          <div>{v.name}</div>
                        </div>
                      </Link>
                    )
                  })}
              </>
            )} */}
      <div
        className=" 
            font-semibold text-lg 
            "
      >
        Popularne miejsca
      </div>
      {popularLocations.map((pl) => (
        <PopularPlaceItem key={pl.name} onClick={() => onPlaceSelected(pl)}>
          {pl.name}
        </PopularPlaceItem>
      ))}

      {/* <Link href={`/miasto/warszawa`}>
            <div className="popularItem">Warszawa</div>
          </Link>
          <Link href={`/miasto/lodz`}>
            <div className="popularItem">Łódź</div>
          </Link>
          <Link href={`/miasto/krakow`}>
            <div className="popularItem">Kraków</div>
          </Link> */}
    </div>
  )
}
